<template xmlns="http://www.w3.org/1999/html">
  <div class="flex-column">
    <!--banner-->
    <div class="banner-box">
      <div class="w1320 flex-column flex-center h100 banner-custom">
        <span class="banner-title animated fadeInDown">智慧园区</span>
        <span class="banner-shuoming animated fadeInDown delay-1s"
          >用3D可视技术搭建物联管控智慧化新园区</span
        >
      </div>
    </div>
    <!--    应用案例-->
    <div class="yingyong-box">
      <div class="w1320 flex-column flex-center h100">
        <span class="yingyong-title">应用案例</span>
        <el-carousel :interval="4000" type="card" height="394px">
          <el-carousel-item v-for="(item, index) in bannerList" :key="index">
            <img :src="item.src" />
            <div class="swiper-box-box flex-column flex-center">
              <span>{{ item.title }}</span>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>

    <!--    产品理念-->
    <div class="w1320 flex-column custom-linian">
      <span>行业痛点</span>
      <div class="flex-row flex-between custom-linian-box">
        <div class="flex-column flex-align custom-item">
          <div class="waiquan flex-column flex-align flex-center">
            <div class="lansequan flex-column flex-align flex-center">
              <img
                src="https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/heqi/pic_chuangxin.png"
              />
            </div>
          </div>
          <span>安全监管难</span>
          <span
            >安全是保障生产的重要前提，安防数字化程度不高，巡更检查、应急指挥以人工为主，存在较大的安全隐患。</span
          >
        </div>

        <div class="flex-column flex-align custom-item">
          <div class="waiquan flex-column flex-align flex-center">
            <div class="lansequan flex-column flex-align flex-center">
              <img
                src="https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/heqi/pic_wending.png"
              />
            </div>
          </div>
          <span>人车环资资产管理繁重</span>
          <span
            >安环、人员、车辆、设备、资料、建筑等资产信息没有成体系的数字化支撑，无法统一监管。</span
          >
        </div>

        <div class="flex-column flex-align custom-item">
          <div class="waiquan flex-column flex-align flex-center">
            <div class="lansequan flex-column flex-align flex-center">
              <img
                src="https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/heqi/pic_anquan.png"
              />
            </div>
          </div>
          <span>态势感知力弱</span>
          <span
            >人员与车辆流动、设备运行与能耗等态势<br />无法实时感知，管理者对园区的态势<br />感知能力弱，整体掌控能力差，<br />易产生管理漏洞。</span
          >
        </div>
      </div>
    </div>

    <!--    解决方案-->
    <div class="jiejuefangan">
      <div class="w1320 h100 flex-column flex-center flex-align custom-jiejue">
        <span>解决方案</span>
        <div class="flex-row">
          <img
            src="https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/park/Solut8.jpg"
          />
          <div class="shuoming flex-column flex-align flex-center">
            <span>
              XX智慧园区解决方案基于物联网平台、XX数据平台和XX低代码开发平台搭建，运用低代码技术配置接入园区智能物联设备，结合XX快速搭建3D园区场景，以可视化的形式应用和展现智慧园区物联监测、管理、运维、应急等业务系统。
            </span>
          </div>
        </div>
      </div>
    </div>

    <!--    业务介绍-->
    <div class="w1320 flex-column flex-align custom-yewu">
      <span>方案特点</span>
      <div class="flex-row flex-between">
        <div
          class="flex-column flex-align custom-yewu-item"
          v-for="(item, index) in yewuList"
          :key="index"
        >
          <div class="img-box">
            <img :src="item.src" />
          </div>

          <span>{{ item.title }}</span>
          <span v-html="item.shuoming"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "parkIndex",
  data() {
    return {
      bannerList: [
        {
          src: "https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/park/1-220PG531133T.jpg",
          title: "智慧园区内部3D可视化模板",
        },
        {
          src: "https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/park/1-220PG5314O22.jpg",
          title: "某大型央企智慧园区项目",
        },
        {
          src: "https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/park/1-220PG53203N9.jpg",
          title: "智慧园区IOC可视化模板—园区整体概况",
        },
        {
          src: "https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/park/1-220PG53130515.jpg",
          title: "智慧园区耗电智能监测",
        },
        {
          src: "https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/park/1-220PG53220645.jpg",
          title: "智慧园区IOC可视化模板-—事件数据分析",
        },
      ],
      yewuList: [
        {
          src: "https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/park/Solut12.jpg",
          title: "01",
          shuoming: "细致还原的3D园区漫游展现<br>",
        },
        {
          src: "https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/park/Solut13.jpg",
          title: "02",
          shuoming: "人、车、环、资、设全种类数据驾驶舱<br>",
        },
        {
          src: "https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/park/Solut14.jpg",
          title: "03",
          shuoming: "覆盖各类IoT智能设备监测、控制、运维管理<br>",
        },
        {
          src: "https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/park/Solut15.jpg",
          title: "04",
          shuoming: "音视频融合的应急指挥响应<br>",
        },
      ],
    };
  },
  mounted() {
    this.$router.afterEach((to, from, next) => {
      window.scrollTo(0, 0);
    });
  },
  methods: {
    goAnchor(selector) {
      document.querySelector(selector).scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped>
.banner-box {
  height: 562px;
  background: url("https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/park/banner_yq.jpg")
    no-repeat center center;
  background-size: auto 100%;
}

.banner-title {
  font-size: 40px;
  line-height: 40px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 56px;
}

.banner-shuoming {
  font-size: 24px;
  line-height: 48px;
  font-weight: 400;
  color: #ffffff;
}

.banner-custom span {
  text-align: left;
}

.yingyong-box {
  height: 810px;
  background: url("https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/heqi/bg_yyal.png")
    no-repeat center center;
  background-size: auto 100%;
}

.yingyong-title {
  font-size: 44px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 94px;
}

/deep/ .el-carousel__indicator--horizontal .el-carousel__button {
  margin-top: 26px;
  background-color: #d2d2d2;
  width: 86px;
  height: 5px;
  opacity: 1;
}

/deep/ .el-carousel__indicator--horizontal.is-active .el-carousel__button {
  width: 86px;
  height: 5px;
  background-color: #ffc244;
  opacity: 1;
}

.custom-linian {
  padding: 108px 0 131px 0;
}

.custom-linian > span {
  font-size: 44px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 50px;
}

.waiquan {
  width: 188px;
  height: 188px;
  border-radius: 50%;
  border: 1px dashed #3485ee;
  transition: all 0.3s ease-out;
}

.lansequan {
  width: 132px;
  height: 132px;
  border-radius: 50%;
  background: #3485ee;
}

.custom-item > span:nth-of-type(1) {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 22px;
  margin-top: 50px;
}

.custom-item > span:nth-of-type(2) {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
}

.custom-item:nth-of-type(1) {
  max-width: 300px;
}

.custom-item:nth-of-type(2) {
  max-width: 300px;
  margin-left: 100px;
  margin-right: 100px;
}

.custom-item:nth-of-type(3) {
  max-width: 300px;
  /*margin-right: 0px;*/
}

.custom-linian-box {
  padding: 0 54px;
}

.jiejuefangan {
  height: 884px;
  background: url("https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/heqi/bg_jiejuefangan.png")
    no-repeat center center;
  background-size: auto 100%;
}

.custom-jiejue > span {
  font-size: 44px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 85px;
}

.shuoming {
  width: 342px;
  height: 533px;
  background: rgba(9, 22, 40, 0.27);
  margin-left: 23px;
}

.shuoming > span {
  display: block;
  width: 286px;
  height: 389px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #fff;
  text-align: justify;
}

.custom-yewu {
  padding: 134px 0 79px 0;
}

.custom-yewu > span {
  font-size: 44px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 80px;
}

.custom-yewu > div {
  width: 100%;
}

.custom-yewu-item > span:nth-of-type(1) {
  font-size: 30px;
  font-weight: bold;
  color: #333333;
  margin: 41px 0 22px 0;
}

.custom-yewu-item > span:nth-of-type(2) {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
}

.waiquan:hover {
  animation: fanzhuan 0.4s forwards;
}

@keyframes fanzhuan {
  0% {
    transform: rotateY(0);
  }
  50% {
    transform: rotateY(90deg);
  }
  100% {
    transform: rotateY(0);
  }
}

.img-box {
  width: 317px;
  height: 202px;
  overflow: hidden;
}

.img-box > img {
  transition: all 0.3s ease-out;
}

.img-box > img:hover {
  transform: scale(1.2);
}

.swiper-box-box {
  height: 90px;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
}

.swiper-box-box > span {
  color: #fff;
  font-weight: bold;
  font-size: 20px;
}

@media screen and (max-width: 1300px) {
  .shuoming {
    /*display: block;*/
    width: 300px;
    /*height: 533px;*/
  }

  .custom-jiejue > div {
    transform: scale(0.9);
    /*width: 600px;
    display: block;
    height: auto;*/
  }

  .custom-yewu > div > div {
    transform: scale(0.9);
  }
}
</style>
